import { Table } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";

import Attendee from "./Attendee";
import LessonSpaceSessionPlaybacks from "./LessonSpaceSessionPlaybacks";
import LoadingSkeleton from "./LoadingSkeleton";
import useGetSession from "./useGetSession";

const Content = ({
  disableJoinButton,
  handleJoinClick,
  showJoinSessionButton,
  uuid,
}) => {
  const { data, hasError } = useGetSession(uuid);
  const {
    date,
    end_time,
    id,
    logs = [],
    primary_tutor,
    start_time,
    student,
    sub_tutor,
  } = data;

  return !Object.keys(data).length ? (
    hasError ? (
      <p className="flex items-center justify-center grow text-red-400">
        There was an error loading session data.
      </p>
    ) : (
      <LoadingSkeleton />
    )
  ) : (
    <>
      <div
        className="pt-2 pb-4 space-y-2"
        data-testid="session-detail">
        <div className="flex items-center justify-between">
          <h2
            className="font-semibold text-xl"
            data-testid="session-detail-header">
            Session Details
          </h2>
          {showJoinSessionButton ? (
            <button
              disabled={disableJoinButton}
              onClick={handleJoinClick}
              data-testid="admin-join-btn">
              Join
            </button>
          ) : null}
        </div>
        <div
          className="text-sm text-zinc-500"
          data-testid="session-detail-date-time">
          {date}
          <br />
          {start_time} - {end_time} EDT
        </div>
      </div>
      <div
        className="pt-6 space-y-2"
        data-testid="session-detail-attendees">
        <h2 className="font-semibold text-xl">Attendees</h2>
        <div className="divide-y divide-zinc-200 space-y-4">
          <Attendee
            displayName={primary_tutor?.display_name || sub_tutor}
            email={primary_tutor?.email}
            label="Tutor"
            minutesLate={primary_tutor?.minutes_late}
            minutesPresent={primary_tutor?.minutes_present}
            startTime={start_time}
          />
          <Attendee
            displayName={student?.display_name}
            email={student?.email}
            label="Student"
            minutesLate={student?.minutes_late}
            minutesPresent={student?.minutes_present}
            startTime={start_time}
          />
        </div>
      </div>
      {id ? (
        <LessonSpaceSessionPlaybacks
          sessionDate={date}
          sessionId={id}
          sessionStartTime={start_time}
        />
      ) : null}
      <div
        className="pt-6 pb-4 space-y-2"
        data-testid="session-detail-Activity">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold text-xl">Activity Feed</h2>
        </div>
        <div
          className="text-sm text-zinc-500 py-2"
          data-testid="activity-feed-table">
          {logs.length > 0 ? (
            <Table
              cellSpacing={Table.CellSpacing.CONDENSED}
              columns={[
                { id: "time", label: "Time" },
                { id: "role", label: "Role" },
                { id: "name", label: "Name" },
                { id: "activity", label: "Activity" },
              ]}
              data={logs.map(log => ({
                ...log,
                id: `${log.time}-${log.name}-${log.role}-${log.activity}`,
              }))}
              variant={Table.Variant.BASIC}
            />
          ) : (
            <em data-testid="no-activity">No activity has occurred yet.</em>
          )}
        </div>
      </div>
    </>
  );
};

Content.propTypes = {
  disableJoinButton: PropTypes.bool,
  handleJoinClick: PropTypes.func,
  showJoinSessionButton: PropTypes.bool,
  uuid: PropTypes.string,
};

export default Content;
